@import "../../styles/mixins.scss";

.menu {
    width: 80vw;
    height: 100%;
    top: 0;
    left: 0%;
    text-align: left;
    position: fixed;
    transition: left 0.3s ease;
    z-index: 10;
    background: rgb(56, 130, 135);
    background: linear-gradient(180deg, rgba(56, 130, 135, 1) -20%, rgba(0, 0, 0, 1) 108%);
    // background-color: white;

    // background: rgb(52,54,70);
    // background: linear-gradient(180deg, rgba(52,54,70,1) 0%, rgba(0,0,0,1) 100%);
}

.menu--hidden {
    left: -80vw;
}

.menu-nav {
    background-color: #0f0f0f;
    height: var(--nav-height);
    padding-left: 1rem;
}

.menu-overlay--open {
    width: 100%;
    height: 100%;
    top: 0;
    right: 100vw;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease;
}

.menu-overlay--hidden {
    left: 0;
}

.menu-container--open {
    right: 80vw;
}

.menu-container--hidden {
    right: 100vw;
}

.menu-overlay {
    width: 100vw;
    height: calc(100vh - var(--nav-height));
    top: var(--nav-height);
    right: -100vw;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    transition: background-color 0.3s ease;
}

.menu-overlay--hidden {
    right: 0;
}

.menu-list {
    list-style: none;
    float: left;
    display: flex;
    flex-direction: column;
    // margin-left: 1.25rem;
    margin-top: 1.25rem;

    &__item {
        width: 80vw;
        color: black;
        font-weight: 400;
        text-align: left;
        font-size: 1.1rem;
        text-transform: uppercase;

        a {
            display: block;
            width: 100%;
            padding: 0.85rem 0 0.85rem 1.85rem;
        }
    }
}

// .menu-footer {
//     position: absolute;
//     bottom: 6vh;
//     left: 50%;
//     transform: translateX(-50%);
//     text-align: center;
// }

.menu-spaceman {
    // width: 40%;
    // margin-left: 40%;
    transform: translateX(-50%) scale(0.8);
    position: absolute;
    left: 50%;
    bottom: 4%;
}

@include breakpoint("l") {
    .menu {
        display: none;
    }
}
