.music-video {
    margin-top: 8px;
    width: 100vw;
    height: auto;
    margin-bottom: 70px;
}

.music-video-header {
    // color: white;
    margin-bottom: 0;
    text-align: center;
    padding: 0 10px;
    color: var(--main);
    margin-top: 80px;
    margin-bottom: 10px;
    line-height: 2.25rem;

    &__date {
        font-size: 1rem;
        text-transform: uppercase;
        font-style: italic;
        font-weight: 400;
        color: var(--text-light);
    }
}

.music-video-leading {
    display: block;
    font-weight: 300;
    color: white;
    margin-bottom: 10px;
}
