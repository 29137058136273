.bio-desktop {
    height: 75vh;
    margin-top: 25vh;
    width: 100%;
    display: flex;
    padding-bottom: 1%;

    .bio-desktop-text {
        width: 60%;
        padding: 0 0 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &__header {
            color: var(--main);
            font-size: 1.7rem;
            font-weight: 500;
            text-align: left;
            width: 90%;
            padding: 0.5rem 40% 0.5rem 8%;
        }

        &__paragraph {
            color: white;
            font-style: italic;
            font-size: 1.375rem;
            text-align: left;
            font-weight: 300;
            padding: 0 30% 0 8%;
        }
    }

    .bio-desktop-cta {
        text-align: left;
        padding-left: 8%;

        a {
            font-weight: 600;
            font-size: 1.25em;
            padding: 7px 15px 8px 15px;
            display: inline-block;
            background-color: var(--main);
            color: var(--background-primary);
            transition: color 0.3s;

            svg {
                margin-left: 14px;
                margin-bottom: 2px;
                transform: scale(1.2);

                path {
                    fill: var(--background-primary);
                    transition: fill 0.3s;
                }
            }

            &:hover {
    
                color: white;
    
                svg {
                    margin-left: 14px;
                    transform: scale(1.2);

                    path {
                        fill: white;
                    }
                }
            }
        }
    }
        

    .bio-desktop-image {
        // padding-left: 2%;
        position: relative;
        width: 38%;
        background-size: cover;

        background-image: url("/resources-internal/group-photo.jpg");
        background-position-y: center;
    
        &__overlay {
            width: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 100%;
            background: linear-gradient(180deg, rgba(15,15,15,1) 0%, rgba(0,212,255,0) 45%);
        }
    }
}