.photography-viewer-header {
    padding-top: var(--nav-height);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    // align-items: center;
    position: fixed;
    padding-left: 1rem;
    width: 100%;
    background-color: var(--background-secondary);
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.4));

    svg {
        // position: fixed;
        // left: 16px;
        // top: 0;
        // transform: translateY(50%);
        // margin-top: 0.5rem;
        z-index: 10;

        path {
            fill: var(--main);
        }
    }

    a {
        padding: 0;
        margin-top: 2px;
        // height: 16px;
    }

    h1 {
        width: 100%;
        color: var(--main);
        text-transform: uppercase;
        margin: 0;
        padding-bottom: 6px;
        padding: 4px 0 8px 1.25rem;
        display: inline-block;
        text-align: left;
        font-size: 1.625rem;
        // position: fixed;
    }
}

.photography-viewer-image {
    max-width: 100vw;
    max-height: 100vh;
    display: block;
}
