.media-viewer-container {
    position: relative;
    height: 100vh;
    top: 0;
    transition: top 0.3s;

    .media-viewer-learn-more {
        svg {
            transform: rotate(0deg) scale(1.1);
            transition: transform 0.3s;

            path {
                fill: white;
                transition: fill 0.3s;
            }
        }
    }

    .media-viewer-info {
        opacity: 1;
        transition: opacity 0.3s ease-in;
    }

    .media-controls-left {
        opacity: 1;
        transition: opacity 0.3s ease-in;
    }

    .media-controls-right {
        opacity: 1;
        transition: opacity 0.3s ease-in;
    }

    .media-controls-indices {
        opacity: 1;
        transition: opacity 0.3s ease-in;
    }

    .media-viewer-cta {
        opacity: 1;
    }
}

.media-viewer {
    position: relative;
    opacity: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    
    transition: opacity 0.2s;

    .media-thumbnail {
        position: absolute;
        left: 50%;
        opacity: 0;
        height: 108%;
        transition: opacity 1.4s, height 0.8s;
        
        opacity: 1;    
    }

    .media-thumbnail--hidden {
        opacity: 0;
    }
    
    .media-viewer-info {
        position: absolute;
        top: 50vh;
        width: 100%;
        transform: translateY(-50%);
        color: white;
        z-index: 8;
        
        &__title {
            position: relative;
            font-size: 3.85rem;
            font-weight: 600;
            user-select: none;
            transition: font-size 0.2s;
            font-style: italic;
            margin-bottom: 4px;

            svg {
                width: 3.85rem;
                height: 3.85rem;
                position: absolute;

                cursor: pointer;

                path {
                    fill: white;
                    transition: fill 0.3s;
                }

                &:hover {

                    path {
                        fill: var(--main);
                    }
                }
            }
        }

        hr {
            width: 20%;
            margin-left: 50%;
            transform: translateX(-50%);
            margin-bottom: 7px;
            border: none;
            height: 2px;
            background-color: white;
        }

        span {
            font-size: 1.8rem;
            font-weight: 300;
            user-select: none;
            transition: font-size 0.2s;

            text-transform: uppercase;
        }
    }
}

.media-viewer--changing {
    opacity: 0;

    .media-thumbnail {
        height: 112%;
    }
}

// TODO: use percentages for padding
.media-controls-left {
    cursor: pointer;
    position: absolute;
    padding: 80px 3% 80px 11%;
    left: 4%;
    top: calc(50% + 55px);
    transform: scale(1.4) translateY(-50%);
    width: fit-content;
    width: -moz-fit-content;
    display: inline-block;
    z-index: 9;

    svg {
        path {
            fill: white;
            transition: fill 0.3s;
        }
    }

    &:hover {
        svg {
            path {
                fill: var(--main);
            }
        }
    }
}

// TODO: use percentages for padding
.media-controls-right {
    position: absolute;
    padding: 80px 11% 80px 3%;
    right: 4%;
    top: calc(50% + 55px);
    transform: scale(1.4) translateY(-50%);
    cursor: pointer;
    // padding: 20px;
    display: inline-block;
    width: fit-content;
    width: -moz-fit-content;
    z-index: 9;

    svg {
        path{
            fill: white;
            transition: fill 0.3s;
        }
    }

    &:hover {
        svg {
            path {
                fill: var(--main);
            }
        }
    }
}

        
.media-controls-indices {
    position: absolute;
    bottom: 21%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    align-items: center;
    z-index: 9;
    
    &__selector {
        position: relative;

        .media-controls-indices__tooltip {
            display: none;

            opacity: 0;
            position: absolute;
            width: max-content;
            top: -48px;
            left: 50%;
            transform: translateX(-50%);
            background-color: var(--background-primary);
            color: var(--main);
            padding: 5px 10px;
            border-radius: 6px;
            transition: opacity 0.2s;
            font-weight: 600;
            user-select: none;

            &::after {
                content: " ";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -7px;
                border-width: 7px;
                border-style: solid;
                border-color: var(--background-primary) transparent transparent transparent;
            }
        }

        .media-controls-indices__dot {
            opacity: 0.5;
            cursor: pointer;
            border-radius: 50%;
            width: 14px;
            height: 14px;
            background-color: white;
            box-sizing: content-box;
        }

        &--active {
            .media-controls-indices__dot {
                transition: opacity 0.5s;
                opacity: 1;
                width: 10px;
                height: 10px;
                cursor: default;
                border: 3px solid var(--main);
            }
        }

        &:hover {
            .media-controls-indices__dot {
                opacity: 1;
            }
        }
    }
}

.media-viewer-learn-more {
    user-select: none;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
    z-index: 8;
    cursor: pointer;

    svg {
        margin-left: 8px;
        margin-bottom: 3px;
    }
}

.media-viewer-cta {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 8;
    font-size: 1.45rem;
    padding: 14px 72px 16px 72px;
    border: 2px solid white;
    border-radius: 8px;
    font-weight: 500;
    background-color: transparent;
    background-color: white;
    transition: background-color 0.3s, color 0.3s, opacity 0.3s;
    cursor: pointer;
    color: var(--background-primary);
    user-select: none;

    &:hover {
        background-color: var(--main-light);
        color: white;
    }

    &:active {
        background-color: var(--main);
    }
}

.media-video-mobile {
    display: none;

    position: absolute;
    width: 100vw;

    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
}

.media-video-mobile--open {
    display: block;

    animation: 0.3s ease 0s 1 normal both running fade-in;
}


// ANIMATIONS

@keyframes pan {
    0% {
        transform: translateX(-49.3%) translateY(-0.7%) scale(1.015);
    }
    100% {
        transform: translateX(-50%) translateY(0%) scale(1.0);
    }
}

@keyframes pan-reverse {
    0% {
        transform: translateX(-50%) translateY(0%) scale(1.0);
    }
    100% {
        transform: translateX(-49.3%) translateY(-0.7%) scale(1.015);
    }
}

@keyframes pan-2 {
    0% {
        transform: translateX(-49.3%) translateY(-0.7%) scale(1.015);
    }
    100% {
        transform: translateX(-50%) translateY(0%) scale(1.0);
    }
}

@keyframes pan-reverse-2 {
    0% {
        transform: translateX(-50%) translateY(0%) scale(1.0);
    }
    100% {
        transform: translateX(-49.3%) translateY(-0.7%) scale(1.015);
    }
}

// UNIVERSAL ANIMATIONS

@keyframes fade-in {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    } to {
        opacity: 0;
    }
}

// VIDEO BACK BUTTON ANIMATIONS

@keyframes slide-up {
    from {
        top: 100vh;
    } to {
        top: calc(100vh - 75px);
    }
}

@keyframes slide-down {
    from {
        top: calc(100vh - 75px);
    } to {
        top: 100vh;
    }
}