@import "../../styles/mixins.scss";

@keyframes fade{
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

.viewer-wrapper {
    padding-top: 150px;
}

.viewer-photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    &-loading {
        margin-top: 18vh;
    }

    img {
        opacity: 0;
    }
}

.viewer-carousel-tag {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 0 7px 0;
    background-color: var(--background-secondary);
    box-shadow: 0px 1px 1px var(--background-primary);
    z-index: 10;

    &__title-container {
        text-align: left;
        margin-left: 2rem;
        padding-bottom: 2px;
    }

    .viewer-carousel-tag__title {
        text-transform: uppercase;
        font-weight: 600;
        color: var(--main);
    }

    a {
        padding-left: 80px;
        display: flex;
        align-items: center;

        svg {
            height: 40px;

            path {
                fill: var(--main);
            }
        }
    }
}