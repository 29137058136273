@import "../../styles/mixins.scss";

.photography-card-container {
    width: 25%;
    height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .photography-card--inactive {
        .photography-card-image {
            filter: grayscale(100%);

            -webkit-transition: 0.25s -webkit-filter linear;
            -moz-transition: 0.25s -moz-filter linear;
            -moz-transition: 0.25s filter linear;
            -ms-transition: 0.25s -ms-filter linear;
            -o-transition: 0.25s -o-filter linear;
            transition: 0.25s filter linear, 0.25s -webkit-filter linear, 0.25s -moz-filter linear, 0.25s transform linear;
        }
    }

    &--active {
        .photography-card {
            &-image {
                filter: grayscale(100%) blur(1px) brightness(80%);
                transform: scale(104%);

                -webkit-transition: 0.25s -webkit-filter linear;
                -moz-transition: 0.25s -moz-filter linear;
                -moz-transition: 0.25s filter linear;
                -ms-transition: 0.25s -ms-filter linear;
                -o-transition: 0.25s -o-filter linear;
                transition: 0.25s filter linear, 0.25s -webkit-filter linear, 0.25s -moz-filter linear, 0.25s transform linear;
            }

            &-shader {
                opacity: 0.575;
            }

            &-text {
                opacity: 1;

                div a {
                    pointer-events: all;
                }
            }
        }
    }

    &--inactive {
        .photography-card {
            &-image {
                filter: grayscale(100%);
            }
        }
    
        .photography-card-title {
            opacity: 0;
        }
    }

    &--mobile {
        width: 100%;
    }
}

.photography-card-title {
    font-size: 1.2rem;
    color: rgb(238, 238, 238);
    background-color: var(--background-primary);
    margin: 0.25rem 0 1.5rem 0;
    padding: 0.2rem 0 0.35rem 0;
    width: 94%;
    border-radius: 0.75rem;

    text-transform: uppercase;
    opacity: 1;
    transition: opacity 0.25s;
}

.photography-card {
    height: 20vw;
    min-height: 420px;
    max-height: 500px;
    width: 90%;
    border-radius: 0.75rem;
    overflow: hidden;

    &-image {
        filter: grayscale(0%);
        max-width: 125%;
        max-height: 145%;
        position: relative;
        top: -210%;
        z-index: 6;
        object-fit: cover;
        // object-position: center;
        transform: scale(100%);
        -webkit-transition: 0.25s -webkit-filter linear;
        -moz-transition: 0.25s -moz-filter linear;
        -moz-transition: 0.25s filter linear;
        -ms-transition: 0.25s -ms-filter linear;
        -o-transition: 0.25s -o-filter linear;
        transition: 0.25s filter linear, 0.25s -webkit-filter linear, 0.25s -moz-filter linear, 0.25s transform linear;
        // border-bottom: 2px solid var(--main);
    }

    &-shader {
        width: 100%;
        height: 100%;
        background-color: var(--main);
        position: relative;
        z-index: 7;
        opacity: 0;
        transition: opacity 0.25s;
    }

    &-text {
        position: relative;
        top: -100%;
        z-index: 9;
        opacity: 0;
        transition: 0.25s opacity;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &__title {
            color: white;
            font-weight: bolder;
            font-size: 2rem;
            text-transform: uppercase;
        }

        hr {
            width: 50%;
            color: white;
            margin: 0.4rem 0 0.4rem 0;
            border: 1px solid white;
        }

        &__desc {
            // margin-top: 0.25rem;
            margin-bottom: 2rem;
            color: white;
            max-width: 85%;
        }

        div a {
            pointer-events: none;
        }
    }

    &--inactive {
        .photography-card-image {
            filter: grayscale(100%);

            -webkit-transition: 0.25s -webkit-filter linear;
            -moz-transition: 0.25s -moz-filter linear;
            -moz-transition: 0.25s filter linear;
            -ms-transition: 0.25s -ms-filter linear;
            -o-transition: 0.25s -o-filter linear;
            transition: 0.25s filter linear, 0.25s -webkit-filter linear, 0.25s -moz-filter linear, 0.25s transform linear;
        }
    }
}

@include breakpoint("l") {

    .photography-card {
        width: 100%;
        border-radius: 0;
        margin: 1rem 0;

        &-title {
            margin-bottom: 0;
        }

        &-text {
            &__title {
                font-size: 2.5rem;
            }

            &__desc {
                margin-bottom: 0;
            }
        }
    }
}

@keyframes to-grayscale {
    from {
        filter: grayscale(0%);
    } to {
        filter: grayscale(100%);
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}