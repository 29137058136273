@import "../../styles/mixins.scss";

html, body {
    width: 100%;
}

@keyframes fade {
    from {
        opacity: 1;
    } to {
        opacity: 0;
    }
}

.home-desktop-view {
    // TODO: change this in JS to be innerHeight
    height: 100%;

    display: none;
    position: relative;

    .media-video-desktop-container {
        top: 0;
    }

    .overlay {
        // position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 40%);
        z-index: 3;
        opacity: 1;

        transition: opacity 0.3s;
    }

    .home-buttons {
        position: absolute;
        z-index: 6;
        top: 82.5vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        > a {
            animation-name: fade-in-2;
            animation-duration: 0.6s;
            animation-timing-function: ease-in;

            margin-top: 1.5rem;
            position: relative;
            font-size: 1.2rem;
            padding: 5px 15px 5px 20px;
            // border: 2px solid white;
            border-bottom: 2px solid transparent;
            transition: border-color 0.3s;
            display: flex;
            align-items: center;
    
            &:hover {
                border-color: var(--main);
            }
    
            svg {
                display: inline-block;
                position: relative;
                fill: white;
                margin-left: 0.5rem ;
                transform: rotate(180deg) scale(0.7);
            }
        }

        .reel-cta {
            a {
                animation-name: fade-in;
                animation-duration: 0.5s;
                animation-timing-function: ease-in;

                font-size: 1.45rem;
                padding: 14px 72px 16px 72px;
                border: 2px solid white;
                border-radius: 8px;
                font-weight: 500;
                background-color: white;
                transition: background-color 0.3s, color 0.3s;
                cursor: pointer;
                color: var(--background-primary);
    
                &:hover {
                    background-color: var(--main-light);
                    color: white;
                }
    
                &:active {
                    background-color: var(--main);
                    color: white;
                }
            }
        }

        .scroll-indicator {
            width: 30px;
            margin-top: 10px;
            animation: fade 1.1s ease-in 0s infinite alternate forwards;
        }
    }


    .home-desktop-mute {
        position: absolute;
        top: 93vh;
        left: 50px;
        transform: scale(1.4);
        z-index: 9;
        cursor: pointer;

        &--hidden {
            display: none;
        }
    }

    .home-desktop-unmute {
        position: absolute;
        top: 93vh;
        left: 50px;
        transform: scale(1.4);
        z-index: 9;
        cursor: pointer;

        &--hidden {
            display: none;
        }
    }
}

.home-background-video-container {
    position: relative;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;
    overflow: hidden;
}

.home-background-video {
    position: fixed;
    left: 0;
    // width: 150%;
    height: 130vh;
    top: -140px !important;
    z-index: 1;
    opacity: 0;
}

.home-mobile-view {
    // box-sizing: content-box;
    position: relative;
    overflow-x: hidden;
    padding-top: var(--nav-height);

    .home-mobile-hero {
        height: calc(100vh - var(--nav-height));
        overflow: hidden;
    }

    .home-buttons {
        position: absolute;
        top: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 6;

        .reel-cta span {
            animation-name: fade-in;
            animation-duration: 0.5s;
            animation-timing-function: ease-in;
        
            font-size: 1.25rem;
            padding: 12px 42px 14px 42px;
            border: 2px solid white;
            border-radius: 8px;
            font-weight: 500;
            background-color: white;
            transition: background-color 0.3s, color 0.3s;
            cursor: pointer;
            color: var(--background-primary);
        
            &:hover {
                background-color: var(--main-light);
                color: white;
            }
        
            &:active {
                background-color: var(--main);
                color: white;
            }
        }
        
        >a {
            animation-name: fade-in-2;
            animation-duration: 0.6s;
            animation-timing-function: ease-in;

            margin-top: 1.75rem;
            position: relative;
            // font-size: 1.2rem;
            padding: 5px 15px 5px 20px;
            // border: 2px solid white;
            border-bottom: 2px solid transparent;
            transition: border-color 0.3s;
            display: flex;
            align-items: center;

            &:hover {
                border-color: var(--main);
            }

            svg {
                display: inline-block;
                position: relative;
                fill: white;
                margin-left: 0.5rem;
                transform: rotate(180deg) scale(0.7);
            }
        }

        .scroll-indicator {
            width: 30px;
            margin-top: 10px;
            animation: fade 1.1s ease-in 0s infinite alternate forwards;
        }
    }

    .media-video-desktop-container {
        
        .shader--fullscreen {
            background-color: black;
        }
    }

    .home-background-video-mobile {
        height: 100%;
        position: relative;
    }

    .home-mobile-mute {
        position: absolute;
        top: 12px;
        right: 16px;
        transform: scale(1.2);
        z-index: 9;

        &--hidden {
            display: none;
        }
    }

    .home-mobile-unmute {
        position: absolute;
        top: 12px;
        right: 16px;
        transform: scale(1.2);
        z-index: 9;
        cursor: pointer;

        &--hidden {
            display: none;
        }
    }
}

.weddings-section {
    padding: 3rem 0 1.5rem 0;
    position: relative;
    z-index: 9;
    background-color: white;

    &-text {
        padding: 0rem 2rem 0rem 2rem;

        &-subheader {
            color: black;
            font-weight: bold;
            margin-bottom: -0.175rem;
        }
    
        &-header {
            font-family: 'Playball', cursive;
            color: black;
            font-size: 4rem;
            margin-bottom: 0.5rem;
        }

        &-cta {
            a {
                margin-top: 1rem;
                margin-bottom: 1rem;
                width: fit-content;
                width: -moz-fit-content;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                z-index: 8;
                font-size: 1rem;
                padding: 5px 0 5px 20px;
                border-bottom: 2px solid transparent;
                transition: border-color 0.3s;
                display: flex;
                align-items: center;
                color: var(--main);

                &:hover,
                &:active {
                    border-color: var(--main);
                }
            }
        
            svg {
                display: inline-block;
                position: relative;
                fill: var(--main);
                margin-left: 0.1rem;
                transform: rotate(180deg) scale(0.5);
            }
        }
    }

    &-images {
        display: flex;
        flex-direction: row;
        gap: 1.5%;
        flex-wrap: wrap;
        padding: 1rem 1.5% 1rem 1.5%;

        &__image {
            width: 49.175%;
            margin-top: 1.5vw;
        }
    }
}

.about-section {
    position: relative;
    z-index: 9;
    background-color: var(--background-primary);
    padding: 2.5rem 0 3.25rem 0;
    display: flex;
    flex-direction: column;
    border-top: 4px solid var(--main);

    @include breakpoint("sl") {
        padding: 2vw 10vw 2vw 10vw;
    }
}

.reel-contact-cta {

    a {
        width: fit-content;
        width: -moz-fit-content;
        position: absolute;
        top: 87.5vh;
        left: 50%;
        transform: translateX(-50%);
        z-index: 8;
        font-size: 1rem;
        padding: 5px 15px 5px 20px;
        // border: 2px solid white;
        border-bottom: 2px solid transparent;
        transition: border-color 0.3s;
        display: flex;
        align-items: center;
    }

    &:hover,
    &:active {
        border-color: var(--main);
    }

    svg {
        display: inline-block;
        position: relative;
        fill: white;
        margin-left: 0.1rem;
        transform: rotate(180deg) scale(0.5);
    }
}

.photography-section {
    padding: 2rem 0 4rem 0;
    background-color: var(--background-secondary);
    position: relative;
    z-index: 6;

    h2 {
        margin-bottom: 1.5rem;
        color: var(--main);
        font-size: 1.6rem;
    }

    &--mobile {
        .photography-section-cards {
            flex-direction: column;
        }
    }
}

.photography-section-cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2.75rem;
}

.contact-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--background-primary);
    position: relative;
    z-index: 6;
    padding: 3rem 2rem 6rem 2rem;

    &-header {
        color: var(--main);
        font-size: 1.7rem;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 0.4rem;
    }

    &-text {
        color: white;
        max-width: 25rem;
        margin-bottom: 2.75rem;
    }

    &-buttons {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin-bottom: 4.5rem;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    } 100% {
        opacity: 1;
    }
}

@keyframes fade-in-2 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@include breakpoint("l") {
    .overlay--fullscreen {
        background-color: black !important;
    }

    .home-mobile-view {
        display: none;
    }

    .home-desktop-view {
        display: block;
    }


    .reel-contact-cta {
        a {
            display: none;
        }
    }

    .weddings-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-text {
            max-width: 70vw;
        }

        &-images {
            align-items: center;
            justify-content: center;
            padding: 0 6% 0 4%;

            &__image {
                width: 23.5%;
            }
        }

    }
    
    .photography-section-cards {
        margin-bottom: 3.25rem;
    }

    .contact-section {
        padding: 3rem 2rem 6rem 2rem;
    }
}

@include breakpoint("xl") {
    .weddings-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5.75rem 0 5.75rem 0;
        // background: linear-gradient(to left, white 0%, #dcfdff 80%);

        &-text {
            text-align: left;
            max-width: 30vw;
            padding: 0 6% 0 6%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &-header {
                font-size: 4rem;
            }

            &-cta a {
                left: 0;
                transform: none;
                padding-left: 0;
            }
        }

        &-images {
            width: fit-content;
            width: -moz-fit-content;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            padding: 0 7% 0 4%;

            &__image {
                width: 235px;
                height: 285px;
                margin-top: 0;
                border-radius: 6px;

                &:nth-child(1) {
                    transform: translateY(14px);
                }

                &:nth-child(2) {
                    transform: translateY(-7px);
                }

                &:nth-child(3) {
                    transform: translateY(7px);
                }

                &:nth-child(4) {
                    transform: translateY(-14px);
                }
            }
        }
    }
}

@include breakpoint("sl") {
    .weddings-section {
        padding: 2vw 10vw 2vw 10vw;
    }

    .photography-section-cards {
        padding: 0 10vw 0 10vw;
    }
}
