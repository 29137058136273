// body,
// html {
//     overflow: auto;
// }

.section-header {
    margin-top: 80px;
    margin-bottom: 4px;
    text-align: left;
    padding-left: 20px;
    font-weight: 600;
    color: var(--main);
}

.info {
    background-color: var(--background-secondary);
    padding: 12px 24px 32px 20px;
    margin-bottom: 50px;

    &__paragraph {
        color: white;
        // font-style: italic;
        text-align: left;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 2rem;
    }

    &__button {
        // background-color: var(--background-primary);
        background-color: var(--main);
        width: fit-content;
        color: var(--background-primary);
        // color: white;
        font-weight: 600;
        font-size: 1.25em;
        margin-top: 20px;
        padding: 7px 15px 8px 15px;
        display: block;

        svg {
            margin-left: 14px;
            transform: scale(1.2);
        }
    }
}

.back-to-top {
    color: white;
    display: block;
    padding: 35px 0 36px 0;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    span {
        padding: 0 2px 2px 2px;
        border-bottom: 1px solid var(--main);
    }
}
