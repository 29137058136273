.button {
    a {
        padding: 0.525rem 2.375rem 0.625rem 2.375rem;
        border-radius: 8px;
        font-weight: bold;
        font-size: 1rem;
    }

    &--primary a {
        background-color: var(--main);
        color: white;

        &:hover {
            background-color: var(--main-dark);
        }
    }

    &--black a {
        background-color: black;
        color: white;
    }

    &--white a {
        background-color: white;
        color: black;
    }
}

.button-outlined {
    
    &--primary a {
        background-color: transparent;
        border: 2px solid var(--main);
        color: var(--main);
        transition: background-color 0.2s, color 0.2s;

        &:hover {
            background-color: var(--main);
            color: white;
        }
    }

    &--black a {
        background-color: transparent;
        border: 2px solid var(black);
        color: black;
    }

    &--white a {
        background-color: transparent;
        border: 2px solid white;
        color: white;
        transition: background-color 0.2s ease-in, color 0.2s ease-in;

        &:hover {
            background-color: white;
            color: black;
        }
    }
}