// @import '../../styles/mixins.scss';

.contact-info {
    padding: 175px 0 85px 0;
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // z-index: 10;
    align-items: center;

    h1 {
        // padding-left: 1rem;
        font-weight: 600;
        font-size: 2.45rem;
        // font-size: 3.5rem;
        // text-align: left;
        color: var(--main);
        margin-bottom: 0.55rem;
        font-style: italic;
        // line-height: 3.5rem;
        // width: 1px;
    }

    hr {
        width: 400px;
        border: none;
        background-color: var(--background-secondary);
        height: 4px;
        margin-bottom: 1.25rem;
    }

    &__names {
        display: block;
        color: var(--main);
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    &__email {
        position: relative;
        z-index: 10;
        margin: 0 auto;
        display: block;
        width: fit-content;
        width: -moz-fit-content;
        font-weight: 300;
        font-size: 1.25rem;
        font-style: normal;
        padding-bottom: 3px;
        // letter-spacing: 1px;
        border-bottom: 2px solid transparent;
        transition: border-color 0.3s;

        &:hover {
            border-bottom: 2px solid var(--main);
        }
    }

    &__phone {
        position: relative;
        z-index: 10;
        margin: 0 auto 24px auto;
        display: block;
        width: fit-content;
        width: -moz-fit-content;
        font-weight: 300;
        font-size: 1.25rem;
        font-style: normal;
        // padding-bottom: 25px;
        // letter-spacing: 1px;
        border-bottom: 2px solid transparent;
        transition: border-color 0.3s;

        &:hover {
            border-bottom: 2px solid var(--main);
        }
    }

    &__socials-tagline {
        display: block;
        font-weight: 600;
        font-style: normal;
        color: white;
        margin-top: 3.5rem;
        margin-bottom: 0.6rem;
    }

    &__socials {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 25px;
        margin-bottom: 12px;
    }
}

.contact-desktop-stars {
    position: absolute;
    bottom: 10px;
    right: 10px;

    z-index: 2;
    opacity: 0.05;
}

@keyframes fade-in {
    from {
        opacity: 0
    } to {
        opacity: 1
    }
}