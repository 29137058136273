.weddings-desktop {
    height: calc(100vh - 39px);
    overflow: hidden;
    padding-top: var(--nav-height);
    background-color: var(--background-primary);

    video {
        position: relative;
        margin-top: -110px;
        // width: 100%;
        height: 135%;
        left: calc((1920px - 100vw) * -0.65);
        // animation-name: fade-in-1;
        // animation-duration: 0.3s;
        // animation-timing-function: ease-in;
    }

    .weddings-shader {
        position: absolute;
        top: 0;
        width: 100vw;
        height: calc(100vh - 39px);

        background: linear-gradient(to right, rgba(0, 0, 0, 0.75) 28rem, rgba(0, 0, 0, 0) 100%);
    }

    .weddings-text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1rem;

        text-align: left;
        padding: 0 4.5% 0 6%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        border-right-width: 2px;
        border-right-style: solid;
        border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.15));
        border-image-slice: 1;

        &-subheader {
            color: white;
            font-weight: bold;
            font-size: 1.125rem;
            margin-bottom: -0.35rem !important;

            animation-name: fade-in-1 !important;
            animation-duration: 0.75s !important;
            animation-timing-function: ease-in;
        }
    
        &-header {
            font-size: 4.875rem;
            font-family: 'Playball', cursive;
            margin-bottom: 0.325rem;

            animation-name: fade-in-2;
            animation-duration: 0.8s;
            animation-timing-function: ease-in;
        }

        p {
            color: white;
            max-width: 23rem;
            margin-bottom: 2.75rem;
            animation-name: fade-in-3;
            animation-duration: 0.95s;
            animation-timing-function: ease-in;
        }
    
        &-button {
            animation-name: fade-in-3;
            animation-duration: 0.95s;
            animation-timing-function: ease-in;
        }

        &-cta {
            animation-name: fade-in-3;
            animation-duration: 0.95s;
            animation-timing-function: ease-in;

            a {
                width: fit-content;
                width: -moz-fit-content;
                z-index: 8;
                font-size: 1rem;
                padding: 5px 0 5px 0;
                margin-top: 1.325rem;
                border-bottom: 2px solid transparent;
                transition: border-color 0.3s;
                display: flex;
                align-items: center;
                color: var(--main);
                
                &:hover,
                &:active {
                    border-color: var(--main);
                }
            }
    
            svg {
                display: inline-block;
                position: relative;
                fill: var(--main);
                margin-left: 0.1rem;
                transform: rotate(180deg) scale(0.5);
            }
        }

        &-facebook {
            animation-name: fade-in-3;
            animation-duration: 0.95s;
            animation-timing-function: ease-in;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            margin-top: 1.75rem;

            span {
                margin-left: 0.425rem;
                font-size: 0.875rem;
                font-weight: bold;
                padding-bottom: 2px;
            }

            svg path {
                transition: fill 0.2s ease-in;
            }

            &:hover {
                svg path {
                    fill: #227AEE;
                }
            }
        }
    }
}

@keyframes fade-in-1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in-2 {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in-3 {
    0% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}