.desktop-video-info-wrapper {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 6;

    &--hidden {
        display: none;
    }
}

.desktop-video-info-overlay {
    width: 100%;
    height: 100%;
    // background-color: var(--background-overlay);
}

.desktop-video-info {
    position: absolute;
    width: 55vw;
    height: auto;
    left: 55%;
    top: 50%;
    transform: translateX(-55%) translateY(-50%);
    z-index: 10;
    // background-color: black;
    background-color: var(--background-primary);

    padding: 1.5rem 2.75rem 2.5rem 2.75rem;
    // border-radius: 0.5rem;
    opacity: 0;
    // border: 1px solid var(--main);
}

.desktop-video-info-sections {
    display: flex;
    // gap: 30px;
    flex-direction: column;
    width: 100%;
    text-align: left;

    &__title {
        font-style: italic;
        font-size: 3rem;
        color: white;
        margin-bottom: 0.55rem;
    }

    hr {
        // width: 20%;
        // margin-left: 50%;
        // transform: translateX(-50%);
        margin-bottom: 0.6rem;
        // opacity: 0;
        border: none;
        height: 2px;
        background-color: white;
        width: 70%;
        }

    &__description {
        font-size: 1.25rem;
        // color: white;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 2.65rem;
        width: 70%;
    }

    &__data {
        font-size: 1.25rem;
        color: rgba(255, 255, 255, 0.8);

        span {
            font-weight: 600;
            color: white;
        }

        &--date {
            margin-bottom: 0.6rem;
        }

        &--location {
            margin-bottom: 1.2rem;
        }
    }

    &__cta-tagline {
        color: var(--main);
        font-style: italic;
        font-weight: 600;
        margin-top: 1.25rem;
        margin-left: 1px;
    }

    &__cta {
        // text-align: left;
        // padding-left: 8%;
        margin-top: 0.3rem;

        a {
            font-weight: 600;
            font-size: 1.25em;
            padding: 7px 15px 8px 15px;
            display: inline-block;
            background-color: var(--main);
            color: var(--background-primary);
            transition: color 0.3s;

            &:hover {
                color: white;

                svg {
                    path {
                        fill: white;
                    }
                }
            }

            svg {
                margin-left: 14px;
                transform: scale(1.2);
                margin-bottom: 2px;

                path {
                    fill: #0F0F0F;
                    transition: fill 0.3s;
                }
            }
        }

    }
}

.desktop-video-info-close {
    margin: 1rem 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

@keyframes fade-in {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    } to {
        opacity: 0;
    }
}