.video-item {
    display: flex;
    row-gap: 0;
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 0;
    padding-top: 56%;

    color: white;
    border: 2px solid white;
    border-width: 2px 0 2px 0;
    transition: padding-top 0.3s, row-gap 0.3s;

    .video-thumbnail {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);

        width: auto;
        height: 100%;

        filter: brightness(0.75);
        transition: filter 0.3s, left 0.3s;
    }

    // container for title, desc, and button
    .video-contents {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__title {
            font-size: 1.75rem;
            font-weight: 400;
            width: 100%;
            color: white;
            transition: top 0.3s, margin 0.3s, font-size 0.3s;
            // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
            margin-bottom: 0;
        }

        &__description {
            opacity: 0;
            display: flex;
            align-items: flex-start;

            color: white;
            // font-style: italic;
            font-weight: 200;
            font-size: 1rem;
            width: 80%;
            height: 0;
            margin-bottom: 0;
            margin-top: 0.5rem;

            transition: opacity 0.3s, height 0.3s, margin-bottom 0.3s;
            overflow-y: hidden;
        }

        .button {
            opacity: 0;
            transition: opacity 0.3s, height 0.3s, padding 0.3s;
            height: 0;
            margin: 0;
            pointer-events: none;
        }

        // &__button {
        //     display: flex;
        //     align-items: center;
        //     border: 1px solid white;
        //     border-radius: 5px;
        //     border-width: 0;
        //     background: none;
        //     padding: 0;
        //     color: white;
        //     opacity: 0;
        // }
    }

    &:not(:first-child) {
        border-top: none;
    }
}

.video-item--expanded {
    padding-top: 85%;

    .video-contents {
        row-gap: 5%;

        &__title {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: -12px;
        }

        &__description {
            opacity: 0.85;
            // margin-bottom: 40px;
            height: 78px;
        }

        // &__button {
        //     opacity: 1;
        //     height: 50px;
        //     padding: 10px 20px 10px 20px;
        //     border-width: 1px;
        // }

        .button {
            opacity: 1;
            pointer-events: all;
            height: -moz-fit-content;
        }

        &__button:hover {
            background-color: var(--main);
        }
    }

    .video-thumbnail {
        filter: brightness(0.25);
    }
}
