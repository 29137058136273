.photography-fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    overflow: hidden;

    .photography-fullscreen-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        z-index: 9;
    }
}

.photography-fullscreen-container--hidden {
    display: none;
}

.react-transform-wrapper {
    z-index: 10;
    overflow: visible !important;
}

.fullscreen-img {
    max-width: 100vw;
    max-height: 100%;
    z-index: 10;
}

.fullscreen-controls {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 75%;
    left: 12.5%;
    bottom: 45px;
}

.fullscreen-controls--rotated {
    transform: rotate(-90deg);
    width: 50px;
    left: 20px;
    top: 0;
    height: 100%;
    z-index: 10;
    transition: transform 0.2s;

    // .fullscreen-rotate-prompt {
    //     transform: rotate(90deg);
    // }

    // .fullscreen-close {
    //     transform: rotate(90deg);
    // }
}

.fullscreen-controls--rotated.fullscreen-controls--rotatable {
    .fullscreen-rotate-prompt {
        transform: scale(1.2);
    }

    .fullscreen-close {
        transform: scale(1.2);
    }
}

.fullscreen-rotate-prompt {
    display: none;
}

.fullscreen-controls--rotatable {
    .fullscreen-rotate-prompt {
        z-index: 10;
        width: 32px;
        margin-left: 70px;
        display: block;
    }
}

.fullscreen-close {
    z-index: 10;
    width: 32px;
}
