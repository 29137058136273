// .media-viewer-container {
    .media-viewer-about-wrapper {
        position: relative;
        padding-bottom: 60px;
        background-color: var(--background-primary);
        
        .media-viewer-about {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: var(--background-primary);
            box-shadow: inset 0 5px 12px -5px rgba(0, 0, 0, 0.4);
            z-index: 10;
            
            &-info {
                height: 100%;
                display: flex;
                flex-direction: column;
                padding-left: 80px;
                padding-top: 2.75rem;
                padding-bottom: 2.75rem;
                z-index: 10;
                justify-content: center;
                width: 45%;

                &__header {
                    color: var(--main);
                    font-size: 1.85rem;
                    margin-bottom: 0.5rem;
                    text-align: left;
                    line-height: 3rem;
                }

                &-text {
                    font-weight: 300;
                    display: flex;
                    flex-direction: row;
                    width: clamp(590px, 100%, 610px);
                    justify-content: space-between;
                    

                    &__paragraph {
                        color: white;
                        width: 75%;
                        text-align: left;
                    }
                }
            }

            &-images {
                // TODO: use clamp() here
                width: calc(100% - 104px);
                overflow-x: hidden;
                margin-left: 64px;

                &__wrapper {
                    position: relative;
                    left: 0;
                    display: flex;
                    width: fit-content;
                    width: -moz-fit-content;
                    padding-left: 16px;
                    padding-right: 16px;
                    justify-content: flex-end;
                    gap: 16px;
                    align-items: center;
                    transition: opacity 0.3s, left 0.3s;
                }

                .media-viewer-about-image {
                    width: 375px;
                    aspect-ratio: 16/9;
                    background-color: var(--background-primary);
                    background-size: cover;
                    background-position-x: center;
                    background-repeat: no-repeat;
                    position: relative;
                    cursor: pointer;
                    border-bottom: 0px solid transparent;
                    transition: border-bottom 0.2s;

                    &:hover {
                        border-bottom: 6px solid var(--main);
                    }

                    &-data {

                        position: absolute;
                        top: 50%;
                        width: 100%;
                        transform: translateY(-50%);
                        color: white;
                        z-index: 10;

                        &__title {
                            position: relative;
                            font-size: 1.9rem;
                            font-weight: 600;
                            user-select: none;
                            transition: font-size 0.2s;
                            font-style: italic;
                            margin-bottom: 4px;
                        }

                        hr {
                            width: 65%;
                            margin-left: 50%;
                            transform: translateX(-50%);
                            margin-bottom: 7px;
                            // opacity: 0;
                            border: none;
                            height: 2px;
                            background-color: white;
                        }

                        span {
                            font-size: 1.25rem;
                            font-weight: 300;
                            user-select: none;
                            transition: font-size 0.2s;

                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

    .media-viewer-about-controls {
        margin-left: 64px;
        display: flex;
        z-index: 10;
        padding: 8px 12px 0 0;
        justify-content: flex-end;
        width: calc(100% - 104px);

        &__right {
            margin-left: 5px;
            cursor: pointer;

            &--inactive {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        &__left {
            cursor: pointer;

            &--inactive {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    .media-viewer-info {
        opacity: 1;
        transition: opacity 0.3s ease-in;
    }
}