.bio {
    margin-top: 10vh;
    width: 100%;
    // display: flex;
    // padding-bottom: 1%; 

    .bio-text {
        width: 100%;
        // padding: 0 0 20px 0;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &__header {
            color: var(--main);
            font-size: 1.3rem;
            font-weight: 600;
            text-align: left;
            width: 90%;
            padding: 2rem 0 0.35rem 0;
            // font-style: italic;
        }

        &__paragraph {
            color: white;
            font-size: 1.15rem;
            text-align: left;
            font-weight: 300;
            // padding: 0 30% 0 8%;
        }
    }

    .bio-image {
        // padding-left: 2%;
        position: relative;
        padding-bottom: 150%;
        margin-top: 2rem;
        background-size: cover;

        background-image: url("/resources-internal/group-photo.jpg");
        background-position-x: center;
    
        &__overlay {
            width: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 100%;
            background: linear-gradient(180deg, rgba(15,15,15,1) 0%, rgba(0,212,255,0) 45%);
        }

    }

    .bio-cta {
        text-align: left;
        padding-left: 2rem;
        margin-top: 35px;

        a {
            font-weight: 600;
            font-size: 1.25em;
            margin-top: 20px;
            padding: 7px 15px 8px 15px;
            display: inline-block;
            // padding: 10px 15px;
            // border-radius: 10px;
            background-color: var(--main);
            color: var(--background-primary);
            // font-weight: 600;

            svg {
                margin-left: 14px;
                transform: scale(1.2);
            }
        }
    }
}