@import "../../styles/mixins.scss";

footer {
    background-color: var(--main);
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
}

.footer--medium-dark {
    background-color: var(--background-secondary);
    color: var(--main);
}

.footer--dark {
    background-color: var(--background-primary);
    color: var(--main);
}

.footer-bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px 0;
}

.footer-socials {
    bottom: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.15rem 0 0.5rem 0;
}

@include breakpoint("l") {
    footer {
        display: block;
    }

    .footer-socials {
        position: absolute;
        left: 70px;
        gap: 0.85rem;
    }
}

// #ig-path {
//     fill: var(--main);
// }

// #yt-path {
//     fill: var(--main);
// }

// #fb-path {
//     fill: var(--main);
// }