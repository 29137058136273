/* Breakpoints */
$media-direction: min;
$breakpoints: (
    'default': '',
    's':       480px,
    'm':       768px,
    'l':       1024px,
    'xl':      1200px,
    'sl':      2250px,

    'mobile': 480px,
    'tablet': 768px,
    'desktop': 1200px,
);

/*
 * Usage:
 *
 * .something {
 *      color: red;
 *
 *      @include breakpoint('m') {
 *          color: blue;
 *      }
 * }
 */
@mixin breakpoint($breakpoint, $direction: $media-direction) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media only screen and (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    }
    @else if unitless($breakpoint) {
        $breakpoint: $breakpoint + 0;
        @media only screen and (#{$direction}-width: $breakpoint) {
            @content;
        }
    }
}

/* hover
 * Usage:
 *
 *  .something {
 *      background: red;
 *
 *      @include hover() {
 *          background: gold;
 *      }
 * }
 */
@mixin hover() {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}

/* ratio
 * Usage:
 *
 * .something {
 *      @include ratio(16, 9);
 * }
 */
@mixin ratio($width, $height) {
    position: relative;

    &::before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
}

/* font weight
 * Usage:
 *
 * .somemthing {
 *      @include fw(--fw-regular);
 * }
 */
@mixin fw($fw) {
    font-weight: var($fw);
    font-variation-settings: 'wght' var($fw);
}

/* z-index
 * Usage:
 *
 * .something {
 *      @include z-index('something');
 * }
 */
$z-layers: (
    'header': 1000,
    'nav': 1050,
    'burger': 1100,
    'logo': 1150,
    'menu': 1200,
);

@function z-index($name) {
    $z: null;
    @if type-of($z-layers) == map { $z: map-get($z-layers, $name); }
    @if type-of($z-layers) == list {
        @for $i from 1 through length($z-layers) {
            @if nth($z-layers, $i) == $name { $z: $i; }
        }
    }
    @if $z { @return $z; }
    @else { @error('Could not find a z-index for `#{$name}`'); }
}
@mixin z-index($name) { z-index: z-index($name); }
