.photography-page-section {
    padding: 1.5rem 0 1rem 0;
    // background-color: var(--background-secondary);
    background-color: var(--background-secondary);
    position: relative;
    z-index: 6;
    margin-top: var(--nav-height);

    h2 {
        margin-bottom: 1.5rem;
        // margin-left: 5%;
        // padding: 0 2rem 0 2rem;
        // text-align: left;
        color: var(--main);
        font-size: 1.6rem;
    }

    &--mobile {
        .photography-page-section-cards {
            flex-direction: column;
        }
    }
}

.photography-page-section-cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2.75rem;
}