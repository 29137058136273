@import "../../styles/mixins.scss";

.contact-container {
    position: relative;

    background: linear-gradient(180deg, rgba(15,15,15,1) 43%, rgba(7,7,7,1) 100%);
}

.contact-info-mobile {

    margin-top: var(--nav-height);
    // padding-bottom: 75px;
    padding: 40px 0 75px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        // padding-left: 1rem;
        font-weight: 600;
        font-size: 1.95rem;
        // font-size: 3.5rem;
        // text-align: left;
        color: var(--main);
        margin-bottom: 0.55rem;
        font-style: italic;
        padding: 0px 22px;
        line-height: 2.55rem;
        // width: 1px;
    }

    hr {
        width: 90%;
        margin-left: 5%;
        border: none;
        background-color: var(--background-secondary);
        height: 4px;
        margin-bottom: 0.4rem;
    }

    iframe {
        width: 100%;
        position: relative;
        height: 1400px;

        @media screen and (max-width: 450px) {
            height: 1450px;
        }

        @media screen and (max-width: 400px) {
            height: 1500px;
        }
    }

    span {
        color: var(--main);
    }

    &__email {
        font-weight: 300;
        font-size: 1.1rem;
        font-style: normal;
        margin-bottom: 1rem;
    }

    &__phone {
        display: block;
        font-weight: 300;
        font-size: 1.1rem;
        font-style: normal;
        margin-bottom: 1rem;
        margin-top: 0.4rem;
    }

    &__socials {
        width: clamp(8rem, 40%, 12rem);
        margin: 0 auto;
        margin-top: 1.5rem;
        display: flex;
        justify-content: space-between;
    }
}

.contact-mobile-stars {
    width: 100vw;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    animation: fade-in 2s ease forwards;
}

@keyframes fade-in {
    from {
        opacity: 0
    } to {
        opacity: 1
    }
}