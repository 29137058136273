@import "../../styles/mixins.scss";

.nav-dark {
    .nav-desktop {
        ul li i {
            color: black;
        }
    }
}

.nav {
    height: var(--nav-height);
    background-color: #0f0f0f;
    width: 100%;

    display: flex;
    position: fixed;
    top: 0;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 0 12px;
    z-index: 10;

    .nav-burger {
        height: calc(var(--nav-height) / 1.5);
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }

    .nav-burger--hidden {
        opacity: 0;
    }

    .nav-logo {
        height: calc(var(--nav-height) * 0.825);
        margin-top: 1px;
    }

    .nav-buffer {
        height: calc(var(--nav-height) / 1.5);
        visibility: hidden;
    }
}

.nav-desktop {
    display: none;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 70px 18px 70px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease-in;

    ul {
        display: flex;
        list-style: none;
        float: right;

        li a {
            color: white;
            text-transform: uppercase;
            margin-left: 26px;
            font-size: 1.125rem;
            font-weight: 500;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
            padding: 3px 10px 3px 10px;
            border-bottom: 2px solid;
            border-color: transparent;
            transition: 0.3s border-color, 0.3s background-color, 0.3s color;
            border-radius: 4px;

            &:hover {
                background-color: white;
                color: black;
                text-shadow: none;
            }
        }

        .current {
            a {
                background-color: white;
                border-bottom: 3px solid var(--main);
                color: black;
                text-shadow: none;
            }
        }
    }

    &--expanded {
        background-color: var(--background-primary);
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.35);

        ul li a {
            text-shadow: none;
        }

        &.nav-light {
            background-color: white;
            
            ul {

                li a {
                    color: black;
                }

                .current a {
                    background-color: var(--background-primary);
                    color: white;
                }
            }
        }
        
        &.nav-primary {
            background-color: var(--main);
        }

        &.nav-medium-dark {
            background-color: var(--background-secondary);
        }
    }
}


@include breakpoint("l") {
    .nav {
        display: none;
    }

    .nav-desktop {
        display: flex;
        
        .nav-logo {
            width: 130px;
            filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.175));
        }
    }

    .nav-light {
        background-color: white;
        
        ul {
            li a {
                color: black;
                text-shadow: none;

                &:hover {
                    background-color: var(--background-primary);
                    color: white;
                }
            }

            .current a {
                background-color: var(--background-primary);
                color: white;
            }
        }
    }

    .nav-logo {
        filter: none !important;
    }
}

@include breakpoint("sl") {
    .nav-desktop {
        padding: 2rem 7.5vw 2rem 7.5vw;
    }
}
