// main {
//     height: 100vh;
// }

.carousel {
    width: 100%;
    height: calc(85vh - 39px);
    top: 15vh;
    // top: 0;
    // background-color: red;
    position: relative;
    // bottom: -20vh;
    overflow: hidden;

    .carousel-container {
        position: relative;
        display: flex;
        width: fit-content;
        gap: 10px;
        height: 100%;
        padding: 10px;
        // background-color: green;
    }
}

.carousel-slide {
    width: 450px;
    height: 100%;
    position: relative;
    background-size: cover;
    background-position-x: center;
    z-index: 9;
    cursor: pointer;
    border-bottom: solid var(--main);
    border-bottom-width: 0;
    transition: border-bottom-width 0.1s;
    

        // -webkit-transition: 0.25s -webkit-filter linear;
        // -moz-transition: 0.25s -moz-filter linear;
        // -moz-transition: 0.25s filter linear;
        // -ms-transition: 0.25s -ms-filter linear;
        // -o-transition: 0.25s -o-filter linear;
        // transition: 0.25s filter linear, 0.25s -webkit-filter linear, 0.25s -moz-filter linear, 0.25s transform linear;

    &__overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 45%);
        cursor: pointer;
    }

    &__title {
        text-transform: uppercase;
        position: absolute;
        left: 1rem;
        bottom: 0.5rem;
        font-size: 2.25rem;
    }

    &:hover {
        border-bottom-width: 5px;
    }
}

.carousel-controls {
    position: relative;
    width: 100%;
    height: 80vh;
    top: -80vh;
    left: 0;
    z-index: 10;
    pointer-events: none;

    &__prev {
        position: absolute;
        width: 10vw;
        height: 80vh;
        left: 0;
        top: 0;
        pointer-events: all;
        cursor: pointer;

        img {
            position: absolute;
            bottom: 50%;
            left: 20px;
        }
    }

    &__prev--hidden {
        display: none;
    }

    &__next {
        position: absolute;
        width: 10vw;
        height: 80vh;
        right: 0;
        top: 0;
        pointer-events: all;
        cursor: pointer;

        img {
            position: absolute;
            bottom: 50%;
            right: 20px;
        }
    }

    &__next--hidden {
        display: none;
    }
}
