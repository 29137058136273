@import 'vars',
    'mixins';

// @import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import url("https://use.typekit.net/ojz6omv.css");
// TODO: serve this locally
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

* {
    margin: 0;

    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // font-family: 'Work Sans', sans-serif;
    font-family: 'acumin-pro', sans-serif;
}

html,
body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    touch-action: pan-y;
    // min-height: 100vh;
    overflow-y: scroll;
    height: 100%;
    position: fixed;
    overflow-x: hidden;
    
    @include breakpoint("l") {
        overflow-y: auto;
        height: auto;
        position: static;
    }
}


h1,
h2,
h3,
h4,
h5,
h6 {
    color: white;
}

a {
    color: white;
    text-decoration: none;
}

p {
    color: var(--text-normal);
    font-size: 1rem;
    line-height: 1.4;
}

strong {
    color: var(--text-dark);
}

ul,
ol {
    margin: 0;
    padding: 0;
}

@media (prefers-reduced-motion) {
  * {
        transition: none !important;
  }
}

