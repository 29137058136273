@import "../../styles/mixins.scss";

.info-section {
    padding-bottom: 3.5rem;
}

.info-section-text {
    padding: 0 1.5rem 1rem 1.5rem;

    h2 {
        color: var(--main);
        margin: 2.5rem 0 0.5rem 0;
        text-align: left;
        font-size: 1.55rem;
    }

    &__content {
        margin-bottom: 2.5rem;

        p {
            color: white;
            margin-bottom: -0.5rem;
            text-align: left;
            max-width: 90%;
        }
    }

    .button {
        text-align: left;
    }
}

.info-section-header {
    color: var(--main);
    margin: 2.5rem 0 0.25rem 0;
}

.info-section-img img {
    width: 100%;
    margin: 1rem 0 2.5rem 0;
    border-bottom: 4px solid var(--main);
}

.info-section--right {
    background-color: var(--background-secondary);
}

.info-section:nth-child(1) {
    .info-section-header {
        margin-top: 1rem;
    }
}

.info-section:nth-child(2) {
    background-color: var(--background-secondary);
}

@include breakpoint("l") {
    .info-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // padding-top: 2rem;
        padding: 2.75rem 0 2.75rem 0;

        &-text {
            padding-top: 3rem;
            margin-left: 6%;
            margin: 0 0 3rem 6%;
            padding-left: 0;

            h2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
            }

            &__content {
                p {
                    max-width: 450px;
                }
            }
        }

        &-img {
            width: 60%;
            max-width: 900px;
            margin-right: 7%;
            border-bottom: none;
            height: 425px;
            overflow: hidden;

            img {
                border-left: 5px solid var(--main);
                height: 100%;
                object-fit: cover;
                object-position: center;
                display: block;
            }
        }

        &:nth-child(2) {
            background: none;
        }
    }
    .info-section--right {
        justify-content: flex-start;

        background-color: var(--background-primary);

        .info-section-text {
            margin-right: 5%;

            &__content {
                margin-bottom: 3rem;
            }
        }

        .info-section-img {
            margin-right: 0;
            margin-left: 5%;
            height: 425px;
            
            img {
                border-right: 5px solid var(--main);
                border-left: none;
                height: 100%;
                object-fit: cover;
                object-position: center;
                display: block;
            }
        }
    }
}