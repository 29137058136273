.media-video-mobile-container {
    // height: calc(100vh - var(--nav-height));
    height: 100%;
    width: 100%;
    position: absolute;

    top: 0;

    .exit-controls {
        display: none;
    }
    
    .shader {
        position: absolute;
        background-color: var(--background-primary);
        opacity: 0;
        // height: calc(100vh - var(--nav-height));
        height: 100%;
        width: 100%;
        // z-index: 9;
    
        transition: opacity 0.3s;
    }
    
    .shader--fullscreen {
        // position: fixed;
        z-index: 10;
        width: 100%;
        height: calc(100vh - var(--nav-height));

        opacity: 1;
    }
    
    .overlay--fullscreen {
        // opacity: 1;
        
        // transition: opacity 0.3s;
    }
    
    .exit-controls {
        display: none;
        background-color: var(--main);
        position: absolute;
        // top: calc(100% - (var(--nav-height) + 75px));
        width: 100%;
        // height: calc((10vw * 0.5625) / 2);
        height: 75px;
        // display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
    
        .exit-controls-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 12px 80px;
    
            .exit-button {
                // width: fit-content;
                // width: -moz-fit-content;
                display: inline-block;
                color: white;
                cursor: pointer;
                margin-right: 15px;
                transform: scale(0.9);
            }
    
            .exit-text {
                font-size: 1.25rem;
                color: white;
                margin-bottom: 2px;
            }
        }

        &--open {
            display: flex;
            animation: slide-up 0.3s forwards;
        }
    }
}