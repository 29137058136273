.media-video-desktop-container {
    position: absolute;
    top: 0;
}

.media-video-desktop {
    display: none;

    position: absolute;
    width: clamp(860px, 75vw, 1300px);

    top: 50vh;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
}

.media-video-desktop-container {
    .shader-fullscreen {
        opacity: 0;
        display: none;
        position: fixed;
        z-index: 7;
        width: 100%;
        height: 100vh;

        transition: opacity 0.3s;
        background-color: var(--background-overlay);
    }
}

.media-video-desktop-container {
    height: 100vh;
    width: 100%;
    position: absolute;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 40%);
        opacity: 1;

        transition: opacity 0.3s;
    }
    
    .shader {
        position: absolute;
        background-color: var(--background-overlay);
        opacity: 0;
        height: 100vh;
        width: 100%;
    
        transition: opacity 0.3s;
        z-index: 5;
    }
    
    .shader-fullscreen--open {
        opacity: 1;
        display: block;
    }

    .exit-controls {
        display: flex;
        background-color: var(--main);
        position: absolute;
        top: 100vh;
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 8;
    
        .exit-controls-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 12px 80px;
    
            .exit-button {
                width: fit-content;
                width: -moz-fit-content;
                display: inline-block;
                color: white;
                cursor: pointer;
                margin-right: 15px;
                transform: scale(0.9);
            }
    
            .exit-text {
                font-size: 1.25rem;
                color: white;
                margin-bottom: 2px;
            }
        }

        &--open {
            animation: slide-up 0.3s forwards;
        }
    }

}

.media-video-desktop--open {
    display: block;
    opacity: 0;
    
    animation: 0.3s ease 0.1s 1 normal both running fade-in;
}