.photography-section-item {
    width: 100%;
    height: 0;
    padding-top: 125%;
    position: relative;

    background-color: wheat;
    border: 1px solid white;
    border-width: 1px 0 1px 0;

    background-size: cover;

    &-container {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        .photography-section-item-title {
            // width: 65%;
            position: absolute;
            left: 0;
            bottom: 32px;
            background-color: var(--background-primary);
            // border-bottom: 5px solid var(--main);
            // border-right: 5px solid var(--main);
            // clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
            filter: drop-shadow(4px 6px 0 var(--main));

            h2 {
                text-align: left;
                z-index: 10;
                font-size: 1.75rem;
                font-weight: 500;
                text-transform: uppercase;
                padding-top: 2px;
                padding-bottom: 4px;
                padding-left: 25px;
                padding-right: 55px;
                font-style: italic;
            }
        }
    }
}
