@import "../../styles/mixins.scss";

.container {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    right: 0;
    // top: calc(var(--nav-height) * -1);
    // height: calc(100% + var(--nav-height));
    // height: 100% !important;
    // background-color: rgb(12, 12, 12);
    background-color: var(--background-primary);
    background-size: cover;
    transition: right 0.3s ease;
}

.container--open {
    right: -80vw;
}

.container--weddings {
    background-color: white;
}

main {
    width: 100%;
    // height: calc(100vh - var(--nav-height));
    // position: relative;
    // overflow-y: hidden;
}

.page-container {
    // position: absolute;
    // top: 0;
    width: 100%;
    height: 100%;
}

@include breakpoint("l") {
    .container {
        height: auto;
        min-height: 100vh;
    }

    main {
        // position: static;
        // height: 100vh;
    }
}
