.music-video-item {
    display: flex;
    row-gap: 0;
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 0;
    padding-top: 56%;

    color: white;
    border: 2px solid white;
    border-width: 2px 0 2px 0;
    transition: padding-top 0.3s, row-gap 0.3s;

    .video-thumbnail {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);

        width: auto;
        height: 100%;

        filter: brightness(0.75);
        transition: filter 0.3s, left 0.3s;
    }

    // container for title, desc, and button
    .video-contents {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__title {
            font-size: 1.75rem;
            font-weight: 500;
            width: 100%;
            color: white;
            transition: top 0.3s, margin 0.3s, font-size 0.3s;
            // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
            margin-bottom: 0;
        }

        &__artist {
            display: flex;
            justify-content: center;
            color: white;
            font-style: italic;
            font-weight: 300;
            width: 80%;
            margin-bottom: 0;

            overflow-y: hidden;
        }

        &__button {
            display: block;
            width: 100%;
            // height: 56vw;
            padding-top: 56%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }
    }

    &:not(:first-child) {
        border-top: none;
    }
}
